<template src="./AfishaGlobal.html"></template>

<script>


export default {
  name: "AfishaGlobal",

  data: () => ({

    theater:0,

    events:[],
    eventsBundle:[],
    events_loaded:false,
    default_logo:'',
    dialog: false,

    eventSelect:{}
  }),

  created() {

    this.loadAfishaGlobal();

    //console.log(this.$route.params);

    if (this.$route.params.tid) {

      // входим под учеткой в этот театр
      // const username = "api_user@zakaz.cloud";
      // const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      // const isAdmin = false;
      // const theater_select = this.$route.params.tid;
      this.theater = this.$route.params.tid;

      //console.log('before');

      this.loadPushkaEvents();

      // store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
      //   //this.$router.push("/buy/12");
      //   console.log('ttttt');
      //   // открываем пушку
      // });

      console.log('after');






    }
    else{
      console.log('нет театра нет входа')
      return '/none'
    }


  },

  computed:{


  },

  testFunct(){
    console.log('testers');
  },
  testFunct2(){
    console.log('testers2');
  },

  mounted() {

    console.log('mount');
  },



  methods:{

    stripslash(str){
      return str.replace(/\\/g, '');
    },


    loadPushkaEvents(){

      console.log('pushka!');

      const post_data = {

        global:{
          format:"raw",
          action:"find",
          module:"event"
        },

        data:{

          mode: {
            target:     "coming",
            date_field: "timedate"
          },

          filter:{
            status: 32,
            pushka: 1,
          },
          fields:{
            id:1,
            pushka:1,
            show_id:1,
            timedate:1,
            status:1,
            prices:1
          },
          param:{
            sort:'timedate',
            desc:false,
            limit:500,

          },
          detail:{
            show_id:{
              fields:{
                id:1,
                name:1,
                age:1,
                time:1
              }
            }
          }
        }
      };

      let pr2 = this.$store.state.api.findEvents(post_data);

      pr2.then( data =>{
        //console.log(data);
        this.events = data;
        this.eventBundlePacking(data);
        this.events_loaded = true;
      })

    },

    onEventClick(id){

      console.log(this.$route.params.tid, id);

      let url = "/b/"+this.$route.params.tid+"/"+id;

      this.$router.push(url);

    },

    testFunc(){
      console.log('tester')
    },




    loadAfishaGlobal(){

      console.log('loadAfishaGlobal!');

      let pr2 = this.$store.state.api.findAfishaGlobal();

      pr2.then( data =>{
        console.log(data);
      })

    },



    //ADD
    showDialog(event){
      this.dialog = true;
      this.eventSelect = event;
    },
    eventBundlePacking(data){
      data.forEach((item)=>{
        let foundBundle = this.eventsBundle.find(el => el.show_id === item.show_id)
        let newEventBundle ={};
        let eventIn = {
          id:item.id,
          timedate:item.timedate,
          timedate_h:item.timedate_h
        };
        switch (foundBundle){
          case undefined:
            newEventBundle = {
              show_id:item.show_id,
              pushka:item.pushka,
              status:item.status,
              show:item.show,
              prices:item.prices,
              img:this.urlAfishaAdress(item.show_id),
              eventsIn:[],
              eventDisplay:false
            };
            newEventBundle.eventsIn.push(eventIn);
            this.eventsBundle.push(newEventBundle);
            break;
          default:
            foundBundle.eventsIn.push(eventIn);
        }
      })
    },
    onImgLoadError(e){
      console.log(e);
      e.img = this.defaultLogo;
      this.$forceUpdate();
    },
    urlAfishaAdress(eventID){
      let path = "https://202702.selcdn.ru/zakaz/d/";
      let theater_folder = "E39FFEA32C";
      let theater_afisha = "/show/" + eventID + "/a.jpg";
      let urlLogo = "";
      if(!this.defaultLogo){
        this.defaultLogo = path + theater_folder + "/show/0/a.jpg";
      }

      switch (this.theater){
          // площадь
        case "1":
          theater_folder = "B6700AADEA";
          break;
        case "2":
          theater_folder = "A1300C78A0";
          break;
        case "3":
          theater_folder = "D81BBC58D4";
          break;
        case "4":
          theater_folder = "F4500EBDC8";
          break;
        case "5":
          theater_folder = "CE223DFBB5";
          break;
        case "6":
          theater_folder = "G0934A7F15";
          break;
        case "7":
          theater_folder = "H37ACA7049";
          break;
        case "8":
          theater_folder = "J853F18DD3";
          break;
      }

      urlLogo = path + theater_folder + theater_afisha;
      console.log(urlLogo);
      return urlLogo;
    },
  }
}



</script>

<style scoped>

.show{
  margin: 25px auto;
  padding: 10px;
  width:600px;
  background-color: #ffec85;
  border-radius: 5px;
  font-size:2rem;
  color:black;
  text-align: center;
  cursor: pointer;
}
.show:hover{

  background-color: #ffbb51;
}

a{
  color:black;
  text-decoration: none;
}
@media(min-width:768px){
  .col-sm-20.col{
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/*add*/
.cardAfisha{
  border-radius: 6px;
  overflow: hidden;
}
.afishaImage{
  height: 360px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}
.afishaImage a{
  display: block;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
}
.afishaImage a img{
  height: auto;
  width: 100%;
  max-width: 100%;
  /*max-height: 100%;*/
  transition: 0.5s;
}
.afishaImage.shadowImage img{
  opacity: 0.5;
  transition: 0.5s;
}
.afishaImage.shadowImage .linkStoped{
  pointer-events: none;
}

.afishaImage a span{
  display: inline-block;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) scale(0);
  padding: 5px 10px;
  border-radius: 3px;
  background: #2196F3;
  color:#fff;
  transition:0.5s;
  box-shadow: 0 0 2px #000;
  font-weight: bold;
  opacity: 0;
}
.afishaImage a:hover span{
  transform:translate(-50%,-50%) scale(1);
  opacity: 1;
}
.afishaImage a span:hover{
  transform:translate(-50%,-50%) scale(1.1);
  opacity: 1;
  box-shadow: 0 0 5px #000;
  transition: 0.5s;
}
/*.afishaImage.shadowImage a span{*/
/*  transform:translate(-50%,-50%) scale(0) !important;*/
/*  opacity: 0 !important;*/
/*}*/
.afishaImage.shadowImage:before{
  content: '';
  display: block;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
}
.afishaImage a:hover img{
  transition:0.5s;
  transform:scale(1.08);
}
</style>
